// import router from "../router/index";
// import store from "../store";
import axios from 'axios';

export default class BusinessService {

    url = process.env.VUE_APP_API_FLUJO_URL;
    rhUrl = process.env.VUE_APP_BACKEND_URL;
    http = axios;
 
    /**
     * SELECT
     * Get business
     */
    async getBusinessSelect()
    {
        return this.http.get(`${this.url}/external/business`);
    }

    async getSelectTradeName()
    {
        return this.http.get(`${this.url}/catalogs/business/trade-name`)
        .catch(this.handleErrorResponse);
    }

    async getBusinessSelectFiltered()
    {
        return this.http.get(`${this.rhUrl}/flujo/business/filtered`)
        .catch(this.handleErrorResponse);
    }

   
   

}