import BaseApi from "./BaseApi";

export default class PayrollService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * EMPLOYEE
    */
    async paysheetIndex(business)
    {
        return this.http.get(`${this.baseUrl}/payslip/business/${business}`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/payslip/employees/${id}`)
        .catch(this.handleErrorResponse);
    }



    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/payslip/employees/${id}`, data)
        .catch(this.handleErrorResponse);
    }
    


    /**
     * PAYROLL
    */
    async indexPayrollPayments(business, type, start, end)
    {
        return this.http.get(`${this.baseUrl}/payslip/payroll-payments/business/${business}/${type}/${start}/${end}`)
        .catch(this.handleErrorResponse);
    }

    async setPayment(business, type, start, end, data)
    {
        return this.http.post(`${this.baseUrl}/payslip/payroll-payments/business/${business}/${type}/${start}/${end}`, data)
        .catch(this.handleErrorResponse);
    }

    async cancelPayment(id)
    {
        return this.http.delete(`${this.baseUrl}/payslip/payroll-payments/${id}`)
        .catch(this.handleErrorResponse);
    }


    async cancelMultiplePayments(data)
    {
        return this.http.delete(`${this.baseUrl}/payslip/payroll-payments/many`, { data: data })
        .catch(this.handleErrorResponse);
    }


    async readPayFile(business, type, start, end, file)
    {
        return this.http.post(`${this.baseUrl}/payslip/payroll-payments/business/${business}/${type}/${start}/${end}/read-file`, file)
        .catch(this.handleErrorResponse);
    }

    downloadFormatExample()
    {
        return this.http.get(`${this.baseUrl}/rrhh/payroll-payments/example-file`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }






    /**
     * ANNUAL BUDGET
    */
    async annualBudget(year, businessData)
    {
        return this.http.post(`${this.baseUrl}/payslip/reports/annual-budget/${year}`, businessData)
        .catch(this.handleErrorResponse);
    }

    exportAnnualBudget(year, businessData)
    {
        return this.http.post(`${this.baseUrl}/payslip/reports/annual-budget/${year}/export`, businessData,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }



}