const headers = [
    {
        text: 'Todos',
        align: 'left',
        value: 'all',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Crear',
        align: 'left',
        value: 'create',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Ver',
        align: 'left',
        value: 'show',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Editar',
        align: 'left',
        value: 'update',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Borrar',
        align: 'left',
        value: 'delete',
        sortable: false,
        class: 'table-b-border  black--text'
    },
   
]

const modules = [
  // {label:"Root", submodules:[ {key:"root", label: "Administrador"} ]},
    {
        props: {
            headers: [
                {
                    text: 'RRHH',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            
        },
        label: "RRHH",
        submodules: [
            
            { key: "employees", label: "Empleados", notIn: [] },
            { key: "attendances", label: "Asistencias", notIn: [] },
            { key: "vacations", label: "Vacaciones", notIn: [] },
            { key: "calendar", label: "Calendario", notIn:["create", "update", "delete"] },
            { key: "vacancies", label: "Vacantes", notIn: [] },
            { key: "candidates", label: "Candidatos", notIn: [] },
            { key: "surveys", label: "Cuestionarios", notIn: [] },
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Patrimonio',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Patrimonio",
        submodules: [
           
            { key: "business_assets", label: "Patrimonio", notIn: [] },
            { key: "maintenances", label: "Mantenimientos", notIn: [] },
           
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Catálogos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Catálogos",
        submodules: [
           
            // { key: "areas", label: "Áreas", notIn: [] },
            { key: "positions", label: "Puestos", notIn: [] },
            { key: "branch_offices", label: "Locaciones", notIn: [] },
            { key: "departments", label: "Departamentos", notIn: [] },
            { key: "holidays", label: "Días festivos", notIn: [] },
           
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Accesos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Accesos",
        submodules: [
           
            { key: "users", label: "Usuarios", notIn: [] },
            { key: "roles", label: "roles", notIn: [] },
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Eventos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Eventos",
        submodules: [
           
            { key: "events", label: "Eventos", notIn: [] },
           
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Avisos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Avisos",
        submodules: [
            { key: "announcements", label: "Avisos", notIn: [] },
        ],
    },

    //special employee role

    {
        props: {
            headers: [
                {
                    text: 'Empleado',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Empleado",
        submodules: [
            { key: "employee_site", label: "Perfil empleado", notIn: ["create", "update", "delete"] },
        ],
    },

    {
        props: {
            headers: [
                {
                    text: 'Nómina',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Permisos",
        submodules: [
           
            { key: "payslip", label: "Empleados", notIn:["create", "delete",] },
            { key: "payroll_payments", label: "Nómina", notIn:[] },
            { key: "payslip_reports", label: "Presupuesto anual", notIn:["all", "create", "update", "delete"] },
            
        ],
    },
    
    
    
];

export default modules;
