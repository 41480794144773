<template>
  <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5">
                        <div class="font-weight-bold body-1 ">
                            Detalles activo
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5" class="py-0 my-0">
                        <p class="primary--text font-weight-bold body-2 pb-0 mb-0">Datos generales</p>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5" class="py-0 my-0" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>

                <v-row justify="center">
                    <v-col cols="12" md="5" class="py-0 my-0">
                        <Separator v-if="images.length > 0" title="Fotografías" :hideLine="true"></Separator>
                        <ImageSlide
                            v-if="images.length > 0"
                            :images="images"
                            :mini="true"
                        ></ImageSlide>
                    </v-col>
                </v-row>

                <v-row justify="center" class="pt-4">
                    <v-col cols="12" md="5" class="py-0 my-0">
                        <p class="primary--text font-weight-bold body-2 pb-0 mb-0">HISTORIAL DE RESPONSABLES</p>
                    </v-col>
                </v-row>

                <v-row justify="center" class="pt-1">
                    <v-col cols="12" md="5" class="py-0 my-0">
                        
                        <span v-if="resps.length == 0" class="caption">No hay responsables registrados</span>
                       
                        <details-item :detailsItems="resps" :onlyLastLine="true"></details-item>


                    </v-col>
                </v-row>

                <v-row justify="center" class="pt-4">
                    <v-col cols="12" md="5" class="py-0 my-0">
                        <p class="primary--text font-weight-bold body-2 pb-0 mb-0">HISTORIAL DE MANTENIMIENTOS</p>
                    </v-col>
                </v-row>

                <v-row justify="center" class="pt-0">
                    <v-col cols="12" md="5" class="py-0 my-0">

                        <span v-if="maintainances.length == 0" class="caption">No hay mantenimiento registrados</span>
                       
                        <v-expansion-panels  v-for="(item, index) in maintainances" :key="index" class="my-2">
                            <v-expansion-panel
                                class="inventory-expansion-panel"
                            >
                            <v-expansion-panel-header class="inventory-expansion-header">
                                {{item.title}}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="inventory-expansion-content" >
                                <details-item :detailsItems="item.concepts" :onlyLastLine="false"></details-item>
                                <ImageSlide
                                    v-if="item.images.length > 0"
                                    :images="item.images"
                                    :mini="true"
                                ></ImageSlide>

                            </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>


            </div>
		

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
  </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
import ImageSlide from '../../../components/ImageSlide.vue';
import Separator from '../../../components/Separator.vue';
export default {
    components: {
        DetailsItem,
        ImageSlide,
        Separator
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            images:[
            ],
            items: [
                { concepto: 'Folio', index: 'id', valor: null },
                { concepto: 'Modelo', index: 'model', valor: null},
                { concepto: 'Marca', index: 'brand', valor: null},
                { concepto: 'No. de serie', index: 'serial_number', valor: null},
                { concepto: 'Color', index: 'color', valor: null},
                { concepto: 'Comentarios', index: 'comments', valor: null},
                { concepto: 'Responsable', index: 'employee.name', valor: null},
                { concepto: 'Empresa', index: 'employee.current_contract.branch_office.company.name'},
                { concepto: 'Fecha de compra', index: 'purchase_date'}
            ],
            resps:[
              
            ],

            maintainances:[
                // {title: "test title", concepts: [], images:[]}
            ],

           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Inventory",	
                block:false,	
                click: () => {}
            },
         
          
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
         onResize () {
            
            if(window.innerWidth < 600){
                this.cancelBtnData.block = true;
            }
            else{
                this.cancelBtnData.block = false;
            }
        },
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.inventory.get(this.id)
			.then((response) => {
                this.items.forEach((y) => {
                    if(y.index == "purchase_date")
                        y.valor = Utils.getObjectValue(response.data,y.index) != null ?  Utils.globalDateFormat(Utils.getObjectValue(response.data,y.index))??'Sin asignar' : "sin asignar";
                    else
                        y.valor = Utils.getObjectValue(response.data,y.index)??'Sin asignar';
                });
                // this.items= [
                //     { concepto: 'Nombre', index: 'name', valor: response.data.name },
                //     { concepto: 'Empresa', index: 'company.name', valor: response.data.company.name},
                    
                // ];

                response.data.photos.forEach(item => {
                    this.images.push({url: item.photo_url});
                });

                if(response.data.employee != null && response.data.responsables.length == 0){
                    this.resps = [
                        // { concepto: 'Maria', index: 'id', valor: "20/04/2024 - 12/08/2024 " },
                        { concepto: response.data?.employee?.name??"", index: 'id', valor: `` },
                    ];
                }else if(response.data.responsables.length > 0){
                    response.data.responsables.forEach(p => {
                        this.resps.push(
                            {concepto: p.responsable.name, index: 'responsible', valor: `${Utils.globalDateFormat(p.loan_date??"")} - ${p.return_date == null ? 'Actualidad' : Utils.globalDateFormat(p.return_date??"")}`}
                        );
                    });
                }


                this.$api.inventory.indexMaintenanceByAsset(this.id)
                .then((responseMainte) => {
                    this.maintainances = responseMainte.data.map((item) => {


                        return {
                            title: Utils.globalDateFormat(item?.date??""), 
                            concepts: [
                                { concepto: 'Tipo', index: 'id', valor: item.type??"" },
                                { concepto: 'Motivo', index: 'id', valor: item?.reason??"" },
                                { concepto: 'Diagnostico', index: 'id', valor: item?.diagnosis??""},
                                { concepto: 'observaciones', index: 'id', valor: item?.observations??""},
                                { concepto: 'Fecha del recordatorio', index: 'id', valor: item.next_maintenance_date != null ? Utils.globalDateFormat(item.next_maintenance_date??"") : ""},
                                { concepto: 'Fotografías', index: 'id', valor: "", noline:true },
                            ], 
                            images: item.photos.map((ph) => {

                                ph['url'] = ph.photo_url;
                                return ph;
                            })
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
                

                // this.maintainances = [
                //     {
                //         title: "14/09/2023", 
                //         concepts: [
                //             { concepto: 'Tipo', index: 'id', valor: "Preventivo" },
                //             { concepto: 'Motivo', index: 'id', valor: "Reasignación de equipo" },
                //             { concepto: 'Diagnostico', index: 'id', valor: "osdfijsodifjsodifjs dofisj dofijsd fsdfoaijdf saodfijsdfopsidfj s odifj" },
                //             { concepto: 'observaciones', index: 'id', valor: "Reasignacidsf skdjhskdfhsdflsjidhf lsdjfh lasdfj hlsdfj hldskfhlsdk fjhlsd fkjdhlksjdhf lsdkjfhkdhfkjdhfkjdhfkdjhf" },
                //             { concepto: 'Fecha del recordatorio', index: 'id', valor: "05/09/2025" },
                //             { concepto: 'Fotografías', index: 'id', valor: "", noline:true },
                //         ], 
                //         images:this.images
                //     },
                //     {
                //         title: "14/09/2023", 
                //         concepts: [
                //             { concepto: 'Tipo', index: 'id', valor: "Preventivo" },
                //             { concepto: 'Motivo', index: 'id', valor: "Reasignación de equipo" },
                //             { concepto: 'Fotografías', index: 'id', valor: "", noline:true},
                //         ], 
                //         images:this.images
                //     }
                // ];
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				// this.loading = false;
                // this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style lang="scss">

    .inventory{
        &-expansion-panel::before{
            // box-shadow: none !important;

        }
        &-expansion-header{
            padding: 1px 10px;
            min-height: 40px !important
        }

        &-expansion-content .v-expansion-panel-content__wrap{
            padding: 3px 10px !important;
        }
    }
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 