<template>
   
    <div :class="`separator-container ${hideLine ? 'separator-just-start' : ''}`">
        <p class="separator-title">{{title}}</p>
        <div v-if="!hideLine" class="separator-line"></div>
    </div>
   
</template>
<script>
export default {
    props: ['title', 'hideLine'],
   
    computed: {
      
    }
}
</script>

<style lang="scss">
    .separator{
        &-container{
            display: flex;
            align-items: center;
            
        }

        &-just-start{
            justify-self: start;
        }

        &-title{
            white-space: nowrap;
            padding: 8px 10px 8px 0px;
            margin: 0 auto 0 auto !important;
            text-align: left;
            font-weight: bold;
            color: #C2C2C2;
            font-size: 1rem;
        }

        &-line{
            background-color: #C2C2C2;
            width: -webkit-fill-available;
            height: 3px;
        }
    }
</style>