<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-5">
                        <div class="font-weight-bold body-1">
                            Nueva vacante
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <jobs-form ref="JobsForm" :values="form" @save="save" :serverErrors="serverErrors"></jobs-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import JobsForm from "./Form.vue";
import Utils from "../../../../helpers/Utils";
export default {
    components: {
        'jobs-form': JobsForm,
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            form: {
                company_id: '',
                branch_office_id: '',
                // area: '',
                position_id: '',
                reason: '',
                request_date: '',
                waiting_date: '',
                manager_position: '',
                manager_id: '',
                quantity: '',
                age: '',
                working_hours: ''
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Jobs",	
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                loading: false,
                block:false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
		validate () {
            // return true;
			return this.$refs.form.validate();
		},
        save(){
            //this.saveBtnData.loading = true;
            //this.$store.state.loading = true;
           
            // this.$store.state.overlay = true
            console.log(this.form);
            if(this.validate()){
                
                // let form = JSON.parse(JSON.stringify(this.form));
                let request = this.prepareRequest(this.form);
                
                this.$api.jobs.store(request)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'Jobs'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        prepareRequest(data)
        {
            let newData = JSON.parse(JSON.stringify(data));

            newData.reason = data.reason?'NEW ONE':'REPLACEMENT';
            newData.quantity = parseInt(data.quantity);

            //!Remove when back accepts strings
            //newData.age = parseInt(data.age);
         

            return newData;
        }
    },
}
</script>

<style>

</style>