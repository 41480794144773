<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center" >
                    <v-col class="text-left col-md-6">
                        <div class="font-weight-bold body-1">
                            Registrar mantenimiento
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <maintenance-form ref="MaintenanceForm" :values="form" @save="save" :serverErrors="serverErrors"></maintenance-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import PrimaryButton from '../../../components/primaryButton.vue';
import MaintenanceForm from "./FormMaintenance.vue";
import Utils from "../../../helpers/Utils";
import Object2Form from '../../../helpers/Object2Form';
export default {
    components: {
        'maintenance-form': MaintenanceForm,
        PrimaryButton,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            serverErrors: {},
            form: {
                num:"",
                description: "",
                brand: "",
                type: "",
                reason: "",
                diagnosis: "",
                observations: "",
                check_maintenance: 0,
                next_maintenance_date: "",



                imgs:[],
                photo_files:[],
                files_url:"",
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Inventory",	
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        index(){
            this.loading = true;
            this.$store.state.loading = true;
			this.$api.inventory.get(this.id)
			.then((response) => {
                this.form.num = "00002";
                this.form.description = response.data.description;
                this.form.brand = response.data.brand;

          
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
        },
		validate () {
            //return true;
			return this.$refs.form.validate();
		},
        save(){
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
           
          

            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                
                this.$api.inventory.storeMaintenance(this.id, form)
                    .then((response) => {
                        console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'Inventory'});
                    })
                    .catch((error) => {
                        console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                this.saveBtnData.loading = false;
                this.$store.state.loading = false;
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));


            //new imgs
            if(request.photo_files.length > 0){
                data.photo_files = request.photo_files.map((item) =>{
                    return {photo_file: item};
                });
            }
            if(data.next_maintenance_date == "" || data.check_maintenance == 0){
                delete data.next_maintenance_date;
            }

            delete data.num;
            delete data.description;
            delete data.brand;
            delete data.check_maintenance;
            delete data.files_url;

            console.log(data);

            return Object2Form.objectToFormData(data, '', []);
        }
    },
}
</script>

<style>

</style>