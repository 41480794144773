<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Detalles de la nómina
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>
               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../../components/DetailsItem.vue';
import Utils from '../../../../helpers/Utils';
export default {
    components: {
        DetailsItem
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
            ],
            
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                click: () => {this.backPage()}
            },
         
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.payroll.get(this.id)
			.then((response) => {
                // this.items.forEach((y) => {
                //     y.valor = response.data[y.index]
                // });
                this.items= [
                    { concepto: 'Nombre', index: 'slug', valor: response.data.name },
                    { concepto: 'Empresa', index: 'due_date', valor: response.data.current_contract.branch_office.company.name??""},
                    { concepto: 'Puesto', index: 'slug', valor: response.data.current_contract.position.name??"" },
                    { concepto: 'RFC', index: 'slug', valor: response.data.rfc },
                    { concepto: 'NSS', index: 'slug', valor: response.data.nss },
                    { concepto: 'Infonavit', index: 'slug', valor: response.data.infonavit_number },
                    { concepto: 'Fecha de ingreso', index: 'slug', valor: Utils.globalDateFormat(response.data.current_contract.admission_date??"") },
                    { concepto: 'Periodicidad de pago', index: 'slug', valor: response.data.current_contract.payroll_type??"" },
                    { concepto: 'Sueldo contratado', index: 'slug', valor: Utils.currencyFormat(response.data.signed_salary??0, "") },
                    { concepto: 'Costo integradora/Inpuestos', index: 'slug', valor: Utils.currencyFormat(response.data.current_contract.integradora_cost??0, '') },
                    { concepto: 'Costo total sueldo', index: 'slug', valor: Utils.currencyFormat(response.data.current_contract.total_cost_salary??0, '') },
                    { concepto: 'Sueldo fiscal', index: 'slug', valor: Utils.currencyFormat(response.data.current_contract.fiscal_salary??0, '') },
                    { concepto: 'Sueldo integradora', index: 'slug', valor: Utils.currencyFormat(response.data.current_contract.integrated_salary??0, '') },
                    { concepto: 'Costo mensual', index: 'slug', valor: Utils.currencyFormat(response.data.current_contract.monthly_cost??0, '') },
                    { concepto: 'Costo anual', index: 'slug', valor: Utils.currencyFormat((response.data.current_contract.monthly_cost??0) * 12, '') },
                    { concepto: 'Costo IMSS mensual', index: 'slug', valor: Utils.currencyFormat((response.data.current_contract.ims_cost??0), '') },
                    { concepto: 'Costo Infonavit bimestral', index: 'slug', valor: Utils.currencyFormat((response.data.current_contract.infonavit_cost??0), '') },
                    { concepto: 'Amortización infonavit', index: 'slug', valor: Utils.currencyFormat((response.data.current_contract.amortization_infonavit??0), '') },
                    { concepto: 'Días pendientes de vacaciones', index: 'slug', valor: response.data.vacations_available_days??0 },
                   
                ];

			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},

        backPage()
        {
            this.$router.push({name: 'PayrollEmployees'});
        }
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 