<template>
    <div>
        <div class="select__container rounded">
            <!-- <div class="select__label">
                <span class="select__label--text body-1 red-text">{{label}}</span>
            </div> -->
            
            <div class="select__input">
                
                <v-autocomplete 
                    
                    class="alternative-selector elevation-0"
                    flat
                    color="primary" 
                    v-model="value" 
                    hide-details="auto" 
                    :items="itemsAsc" 
                    
                    dense
                    item-color="primary"
                    background-color='transparent'
                    :rules="rule"
                    :disabled="disabled"
                    id="alt-selector"
                    :label="label"
                    @change="changed"
                ></v-autocomplete>
            </div>
        </div>
       
        <v-progress-linear color="primary" indeterminate  height="2" v-if="loading"></v-progress-linear>
    </div>
    
</template>
<script>
    export default {
        props: ['valueInput', 'rule', 'label', 'items', 'loading', 'disabled', 'sortAsc'],
        computed: {
            value: {
                get: function() {
                    return this.valueInput
                },
                set: function(value) {
                    this.$emit('valueChange', value)
                }
            },
            
            itemsAsc: function() {
                if(this.sortAsc)
                    return [...this.items].sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
                
                return this.items
            },
        
            
        },
      methods:{
            changed(v)
            {
                //console.log('change !', v)
                if(v != null)
                    this.lock = true;
                this.$emit('onChange', v);
            }
        },
    }
    
</script>

<style lang="scss">


    .select{
        &__container{
            padding: 9px 0px 1px 7px;
            border: $lineColor-1 1px solid;
            display: flex;
            align-items: baseline;
        }

        &__label{
            cursor: default;
            min-width: fit-content;
            margin-right: -8px;
            &--text{
                //font-weight: bold !important;
                color: black;
            }
        }

        &__input{
            // margin-left: 10px;
            width: -webkit-fill-available;
            
        }
    }
    .alternative-selector{
        // min-width: 1rem !important;
        // padding: 0.3125rem !important;
        // padding-bottom: px !important;
        margin-top: 0px !important;
        font-size: 0.8rem !important;
        
    }

    // .v-text-field .v-input__control .v-input__slot input, select{
    //   //margin-bottom: 8px;
    //     margin-top: 0px !important;
    //     padding: 0;
    // }
    #alt-selector{
        
        margin-top: 0px !important;
        // font-size: px !important;
        font-weight: 400 !important;
        padding-right: 0 !important;
        
    }
    .border{
        
    }
</style>