
export default class Utils{

    months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    

    halfMonthsYear(year){
        console.log(new Date(`${year}-1-1`), "yearrr")
        let start = new Date(`${year}-1-1`);
        start.setHours(0,0,0,0);
        let end = new Date(`${year}-12-31`);
        end.setHours(0,0,0,0)

        let now = new Date();
        now.setHours(0,0,0,0)
        let broke = false;
        const weeks = [];
        let current = start;
        let valueStart = null;
        let valueEnd = null;
        let active = false;
        console.log(current.getDate(), "curerent", start)
        while (!broke) {
            
            //first month day
            if(current.getDate() == 1){
                // let weekText = `${this.alwaysTwoDigits(current.getDate())}/${this.months[current.getMonth()]}`;
                let weekText = this.dateFormatLocal(current);
                valueStart = current;
                current = current.addDays(14);
                // weekText += ` - ${this.alwaysTwoDigits(current.getDate())}/${this.months[current.getMonth()]}`;
                weekText += ` - ${this.dateFormatLocal(current)}`;
                valueEnd = current;

                if(now >= valueStart && now <= valueEnd)
                    active = true;
                
                valueStart = `${valueStart.toISOString().split("T")[0]}`;
                valueEnd = `${valueEnd.toISOString().split("T")[0]}`;
                weeks.push({text: weekText, value:`${valueStart}|${valueEnd}`, active:active});
                active = false;
            }else{
                // let weekText = `${this.alwaysTwoDigits(current.getDate())}/${this.months[current.getMonth()]}`;
                let weekText = this.dateFormatLocal(current);
                valueStart = current;
                current = this.getLastDayOfMonth(current.getFullYear(), current.getMonth())
                // weekText += ` - ${this.alwaysTwoDigits(current.getDate())}/${this.months[current.getMonth()]}`;
                weekText += ` - ${this.dateFormatLocal(current)}`;
                valueEnd = current;

                if(now >= valueStart && now <= valueEnd)
                    active = true;

                // const fullMonth = (valueStart.getMonth()+1) <= 9 ? `0${valueStart.getMonth()+1}`: valueStart.getMonth()+1;
                valueStart = `${valueStart.toISOString().split("T")[0]}`;
                valueEnd = `${valueEnd.toISOString().split("T")[0]}`;
                weeks.push({text: weekText, value:`${valueStart}|${valueEnd}`, active:active});
                active = false;
                
            }
            current = current.addDays(1);

            if(current > end)
                broke = true;
        }
        return weeks;
    }

    dateFormatLocal(data) {
        const new_date = data;
        if (new_date instanceof Date && !isNaN(new_date)) {
          
  
          let date = data;
  
          let ye = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
            date
          );
          let mo = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
            date
          );
          let da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(date);
          let newDate = `${da}/${mo.charAt(0).toUpperCase() + mo.slice(1)}/${ye}`;
          return newDate;
        }
        return new_date;
    }


    getLastDayOfMonth(year, month) {
        let lastDay =  new Date(year, month + 1, 0);
        lastDay.setHours(0,0,0,0);
        return lastDay;
    }

    /**
     * Returns a 2 digit number always 
     * @param {string} num a number 
     */
    alwaysTwoDigits(num)
    {
        if(num.toString().length == 1)
            return `0${num}`;
        return num;
    }

    static processDaysOff(days){
        const baseWeek =[
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
            "sunday",
        ];
        let lockDays = {
            week:[],
            holidays:[]
        };

        days.resting_days.forEach(day => {
            const dayNumberWeek = baseWeek.indexOf(day);
            // console.log(dayNumberWeek, day, baseWeek[0])
            if(dayNumberWeek >= 0)
                lockDays.week.push(dayNumberWeek);

        });
        days.holidays.forEach(day => {
            lockDays.holidays.push(day.date);
        });
        return lockDays;
    }


    static getLastDayMonth(date)
    {
        //// console.log(date)
        return new Intl.DateTimeFormat("es", { day: "2-digit" }).format(new Date(date.getFullYear(), date.getMonth() +1, 0));
        // return  new Date(date.getFullYear(), date.getMonth() +1, 0).getDate();
    }

    weeksYear(year)
    {
        let start = new Date(`${year}-01-01`);
        start.setHours(0,0,0,0);
        let end = new Date(`${year}-12-31`);
        end.setHours(0,0,0,0)

        let now = new Date();
        now.setHours(0,0,0,0)
        let broke = false;
        const weeks = [];
        let current = start;
        let valueStart = null;
        let valueEnd = null;
        let active = false;

        while (!broke) {
            
            //monday first day
            if(current.getDay() == 1){
                let weekText = `${this.alwaysTwoDigits(current.getDate())}/${this.months[current.getMonth()]}`;
                valueStart = current;
                current = current.addDays(6);
                weekText += ` - ${this.alwaysTwoDigits(current.getDate())}/${this.months[current.getMonth()]}`;
                valueEnd = current;
                //// console.log(now, valueStart, now >= valueStart, now <= valueEnd, this.checkBetweenDates(now.toJSON().slice(0,10), valueStart.toJSON().slice(0,10), valueEnd.toJSON().slice(0,10)));
                if(now >= valueStart && now <= valueEnd)
                    active = true;

                valueStart = `${valueStart.getFullYear()}-${valueStart.getMonth()+1}-${valueStart.getDate()}`;
                valueEnd = `${valueEnd.getFullYear()}-${valueEnd.getMonth()+1}-${valueEnd.getDate()}`;
                weeks.push({text: weekText, value:`${valueStart}|${valueEnd}`, active:active});
                active = false;
            }
            current = current.addDays(1);

            if(current > end)
                broke = true;
        }
        return weeks;
    }

    checkBetweenDates(dateCheck, dateFrom, dateTo)
    {
        // var dateFrom = "02/05/2013";
        // var dateTo = "02/09/2013";
        // var dateCheck = "02/07/2013";

        var d1 = dateFrom.split("/");
        var d2 = dateTo.split("/");
        var c = dateCheck.split("/");

        var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
        var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
        var check = new Date(c[2], parseInt(c[1])-1, c[0]);

        console.log(check > from && check < to)
    }

    static getDateTexts(dateArray){
        let date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);

        let ye = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
            date
        );
        let mo = new Intl.DateTimeFormat("es", { month: "long" }).format(
            date
        );
        let moNumber = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
            date
        );
        let da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(date);
        let daText = new Intl.DateTimeFormat("es", { weekday: "long", }).format(date);

        return {
            year:ye,
            monthText:mo,
            monthNumber:moNumber,
            day:da,
            dayText: daText
        }
    }

    

    //format > dd/mm/yyyy
    static globalDateFormat(data) {
        const new_date = new Date(data);
        if (new_date instanceof Date && !isNaN(new_date)) {
          let dateArray = new Array();
          dateArray[0] = data.substring(0, 4);
          dateArray[1] = data.substring(5, 7);
          dateArray[2] = data.substring(8, 10);
  
          let date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  
          let ye = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
            date
          );
          let mo = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
            date
          );
          let da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(date);
          let newDate = `${da}/${mo.charAt(0).toUpperCase() + mo.slice(1)}/${ye}`;
          return newDate;
        }
        return new_date;
    }

    static timeSince(ts){
        let now = new Date();
        ts = new Date(ts*1000);
        var delta = now.getTime() - ts.getTime();
    
        delta = delta/1000; //us to s
    
        var ps, pm, ph, pd, min, hou, sec, days;
        
        if(delta<=59){
            ps = (delta>1) ? "": "";
            return Math.round(delta)+" seg"+ps
        }
    
        if(delta>=60 && delta<=3599){
            min = Math.floor(delta/60);
            sec = delta-(min*60);
            pm = (min>1) ? "": "";
            ps = (sec>1) ? "": "";
            return min+" min"+pm+" ";
        }
    
        if(delta>=3600 && delta<=86399){
            hou = Math.floor(delta/3600);
            min = Math.floor((delta-(hou*3600))/60);
            ph = (hou>1) ? "s": "";
            pm = (min>1) ? "s": "";
            return hou+" hr"+ph+" ";
        } 
    
        if(delta>=86400 && delta <= 2591999){
            days = Math.floor(delta/86400);
            hou =  Math.floor((delta-(days*86400))/60/60);
            pd = (days>1) ? "s": "";
            ph = (hou>1) ? "s": "";
            return days+" día"+pd+" ";
        }

        if(delta>=2592000){
            days = Math.floor(delta/2592000);
            hou =  Math.floor((delta-(days*2592000))/60/60);
            pd = (days>1) ? "es": "";
            ph = (hou>1) ? "es": "";
            return days+" mes"+pd+" ";
        }
    
    }
    

    static datediff(first, second) {        
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }
    
    /**
     * new Date("dateString") is browser-dependent and discouraged, so we'll write
     * a simple parse function for U.S. date format (which does no error checking)
     */
    static parseDate(str) {
        var mdy = str.split('-');
        return new Date(mdy[0], mdy[1] - 1, mdy[2]);
    }
    /**
     * Get the same day of le last month
     * if day doesnt exist then returns the last day of last month
     * @returns {Date}
     */
    static getStartDate()
    {
        let actual = new Date(); 
        // actual.setTime(actual.getDate() );
        actual.subtractDays(90);
        //// console.log(actual)
        let month = (actual.getMonth()+1) - 0;
        
        let year = actual.getFullYear();
        let day = actual.getDate();
        
        
        //set to december
        if(month == 0){
            month = 12;
            year = year - 1;
        }

        let start = new Date(`${month}-${day}-${year} 00:00:00`);

        //set to last day of past month
        if(actual.getDate() != start.getDate()){
            var d = new Date(year, month, 0);
            return d.toISOString().split('T')[0];
        }

        //same day but past month
        return start.toISOString().split('T')[0];
    } 

    static onlyAddDays(date, days){
        const dt = date.split("-");
        let actual = new Date(`${dt[1]}-${dt[2]}-${dt[0]} 00:00:00`.replace(/-/g, "/"))
        // let actual = new Date(); 
        // actual.setTime(actual.getDate() );
        actual.addDaysV2(days);
        return actual.toISOString().split('T')[0];
    }

    static getDateAddedDays(days)
    {
        // console.log("entta getDateAddedDays")
        let actual = new Date(); 
        // actual.setTime(actual.getDate() );
        actual.addDaysV2(days);
       // console.log(actual)
        let month = (actual.getMonth()+1) - 0;
        
        let year = actual.getFullYear();
        let day = actual.getDate();
        
        // console.log(day, month, year)
        
        //set to december
        if(month == 0){
            month = 12;
            year = year - 1;
        }

        // const dateSt = 

        // let start = new Date(`${month}-${day}-${year} 00:00:00`);
        let start = new Date(`${month}-${day}-${year} 00:00:00`.replace(/-/g, "/"))
        // let test = new Date(`1-12-2024 00:00:00`);
       
        // console.log (new Date('2024-12-12'.replace(/-/g, "/")));
        // console.log(start.toISOString().split('T')[0], "pppoalaoa");
        //set to last day of past month
        if(actual.getDate() != start.getDate()){
            var d = new Date(year, month, 0);
            return d.toISOString().split('T')[0];
        }
        // console.log(start.toISOString().split('T')[0], "aiaiaia");
        //same day but past month
        return start.toISOString().split('T')[0];
    } 

    /**
     *  Number to currency format
     * @param {number} money 
     * @param {string} _currency 
     * @returns {string}
     */
    static currencyFormat(money, _currency = "MXN") {
        //console.log(money);
        // const currency = parseFloat(money).toFixed(2);
        const formatter = new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        });
        return formatter.format(money)+" " + _currency;
    }


    /**
     * Removes first word from a string
     * @param {string} phrase 
     * @returns {string} phrase without first word
     */
    static deleteFirstWord(phrase)
    {
        let spplited = phrase.split(" ");
        spplited.shift();
        return spplited.join(' ');
        
    }

    /**
     * Set page scroll to top.
     */
    static scrollToTop()
    {
        window.scrollTo(0,0);
    }

    /**
     * Capitalize first letter of every word
     * @param {string} phrase 
     * @returns {string} 
     */
    static capitalizePhrase(phrase)
    {
        return phrase.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    }

    /**
     * Returns phrase without a selected word.
     * @param {string} word word to delete
     * @param {string} phrase phrase base
     * @returns {string} new phrase
     */
    static deleteWord(word, phrase)
    {
       // console.log(word, phrase);
        return phrase.replaceAll(word, '');
    }

    /**
     * Check if an object has properties
     * @param {object} obj object to check
     * @returns {boolean}
     */
    static objectIsEmpty(obj) {
        if(typeof obj == 'string')
            return true;
        return Object.keys(obj).length === 0;
    }

    /**
     * Returns date in selected time zone
     * @param {*} date date string | Date
     * @param {string} tzString timeZone
     * @returns {Date}
     */
    static convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date+' 00:00:00') : date).toLocaleString("en-US", {timeZone: tzString}));   
    }


    /**
     * Returns the object's value from a path
     * @param {object} obj 
     * @param {string} path 
     * @returns value
     */
    static getObjectValue(obj, path) {
        if (!path) return obj;
        const properties = path.split('.');
        try {
            return this.getObjectValue(obj[properties.shift()], properties.join('.'))
        } catch (error) {
            return null;
        }
        //return this.getObjectValue(obj[properties.shift()], properties.join('.'))
    }

    /**
     * Trim a string at a length and adds "..." at the end.
     * @param {string} input string
     * @param {number} length 
     * @returns {string}
     */
    static trimStringAt(input, length = 25)
    {
        return input.length > length ? input.substring(0, length - 3) + "...":input;
    }
}


Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
Date.prototype.subtractDays = function (d) {
    this.setTime(this.getTime() 
        - (d * 24 * 60 * 60 * 1000));
    return this;
}
 
Date.prototype.addDaysV2 = function (d) {
    this.setTime(this.getTime() 
        + (d * 24 * 60 * 60 * 1000));
    return this;
}