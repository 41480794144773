<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center" justify="space-between">
					<v-col cols="12" md="2" lg="2" xl="3" class="mx-0 px-0 py-0">
						<v-row class="" justify="start" no-gutters>
						
							<v-col class="">
								<AlternativeAutocompleteOne
                                    :label="'Empresa'"
									:items="serverItems.companies"
                                    :valueInput="selectedCompany"
                                    :loading="loadingCompanies"
									@valueChange="(v) => {selectedCompany = v; selectedOffice = '';  this.getBranchOffices(); index()}"
                                    :sortAsc="true"
								></AlternativeAutocompleteOne>
							</v-col>
						</v-row>
					</v-col>
                    <v-col cols="12" md="2" lg="2" xl="3" class="mx-0 px-0 px-lg-1 py-3 py-lg-0">
						<v-row class="" justify="start" no-gutters>
						
							<v-col class="">
								<AlternativeAutocompleteOne
                                    :label="'Sucursal'"
									:items="serverItems.offices"
                                    :valueInput="selectedOffice"
                                    :loading="loadingOffices"
									@valueChange="(v) => {selectedOffice = v; index()}"
								></AlternativeAutocompleteOne>
							</v-col>
						</v-row>
					</v-col>
                     <v-col cols="12" md="2" lg="2" xl="1" class="mx-0 px-0 py-0">
						<v-row class="" justify="start" no-gutters>
						
							<v-col class="">
								<AlternativeAutocompleteOne
                                    :label="'Estatus'"
									:items="serverItems.estatus"
                                    :valueInput="selectedStatus"
                                    
									@valueChange="(v) => {selectedStatus = v; index()}"
								></AlternativeAutocompleteOne>
							</v-col>
						</v-row>
					</v-col>
					<v-spacer></v-spacer>
                    <v-col cols="12" lg="6" md="6" xl="5">
                        <v-row justify="end">
                            
                            <v-col v-permission="'employees.create'" xl="5" md="5" class="mx-0 px-0 px-md-1 ">
                                <primaryButton :props="createBtnData"></primaryButton>
                            </v-col>
                            <v-col cols="1" class="mx-0 mr-md-3 mr-lg-0 mr-xl-0 px-1 pl-1 width-fit text-right">
                                <iconButton
                                    :props="{
                                    color:'primary',
                                    icon:'mdi-magnify',
                                    
                                    height:'35',
                                    click: () => {openModalSearch()}
                                    }"
                                ></iconButton>
                            </v-col>
                            <v-col cols="1" class="mx-0 mr-md-3 mr-lg-0 mr-xl-0 px-1 pl-1 width-fit text-right">
                                <iconButton
                                    :props="{
                                    color:'primary',
                                    icon:'mdi-progress-download',
                                    iconClass: 'flip',
                                    height:'35',
                                    click: () => {openExportModal()}
                                    }"
                                ></iconButton>
                            </v-col>
                            <v-col v-permission="'employees.delete'" cols="1" class="mx-0 mr-md-3 mr-lg-0 mr-xl-0 px-1 pl-1 width-fit text-right">
                                <iconButton
                                    :props="{
                                    color:'primary',
                                    icon:'mdi-account-off-outline',
                                    iconClass: 'flip',
                                    height:'35',
                                    click: () => {goToLayoff()}
                                    }"
                                ></iconButton>
                            </v-col>
                        </v-row>
                    </v-col>
                   
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatable :props="tableProps" @method_handler="method_handler" class="table-employess">
							<!-- chip documentation item -->
							<template v-slot:[`item.documentation`]="{ item }">
								<v-chip class="chip-employees-index"  dark label :color="getColorDocumentation(item.documentation)" >{{item.documentation[0]}}</v-chip>
							</template> 
							<!--  -->
						</datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <!-- SEARCH -->
        <modal :props="modalSearchProps" @modalResponse="method_handler">
            <!-- Body -->
           
            <template slot="cardBody">
				
                <v-row class="mt-1">
                    <v-col class=" pr-0">
                        <textFieldForm
                            ref="searchInput"
                            label="Nombre del empleado"
                            :valueInput="searchName"
                            :rule="[]"
                            @valueChange="(v) => {searchName = v.trim(); }"
                            @enterPressed="() => {this.search()}"
                        ></textFieldForm>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <primaryButton
						:props="searchModalBtnData"
						></primaryButton>
                    </v-col>

                </v-row>

                <v-row>
					<v-col class="mx-0 px-0">
						<datatable :props="tableSearchProps" @method_handler="method_handler">
							
						</datatable>
					</v-col>
				</v-row>
                    
			</template>
			<!-- Buttons -->
			
		</modal>

        <!-- SHARE  -->
        <modal :props="modalProps" @modalResponse="method_handler">
            <!-- Body -->
           
            <template slot="cardBody" v-if="!loadingLink">
				
                <v-row class="mt-1">
                    <v-col class=" pr-0">
                        <div class="container-input-share">
                            <v-text-field
                            v-on:focus="$event.target.select()" 
                            ref="clone" 
                            class="outside"
                            color="transparent"
                            v-model="shareValueUrl" 
                            hide-details="auto" 
                             outlined
                            dense 
                            readonly
                            
                        ></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <secondaryButton
						:props="cancelModal"
						></secondaryButton>
                    </v-col>

                </v-row>
                    
			</template>
			<!-- Buttons -->
			<template slot="cardActions" v-if="!loadingLink">
                
				<v-row justify="end">
					
					<v-col cols="4" class="pl-4 mr-2">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>


        <!-- DELETE -->
        <modal :props="modalDeleteProps" @modalResponse="method_handler">
            <template slot="cardBody">
				<p>
                    ESTAS A PUNTO DE DAR DE BAJA A UN EMPLEADO, 
                    ESTO PROVOCARA QUE ESTE DEJE DE SER CONSIDERADO PARA CUALQUIER 
                    FUNCIONALIDAD DEL SISTEMA Y DESAPARECERÁ DE ESTA LISTA. 
                    PARA CONFIRMAR QUE DESEAS ELIMINAR AL EMPLEADO ESCRIBE <span class="font-weight-black">{{confimPlaceholder}}</span> Y HAZ CLIC EN ELIMINAR
                </p>
                <v-row class="mt-1">
                    <v-col class=" pr-0">
                        <div class="container-input-share">
                            <v-text-field
                            
                            
                            class="outside"
                            color="black"
                            v-model="confirmNameInput" 
                            hide-details="auto" 
                            outlined
                            dense
                            :placeholder="confimPlaceholder"
                            
                            
                        ></v-text-field>
                        </div>
                    </v-col>
                   

                </v-row>
                <v-row class="mt-2">
                    <v-col class=" pr-0">
                        <div class="container-input-share">
                            <v-textarea
                            
                            rows="2"
                            class="outside"
                            color="black"
                            v-model="reasonInput" 
                            hide-details="auto" 
                            outlined
                            dense
                            placeholder="MOTIVO DE BAJA"
                            
                            
                        ></v-textarea>
                        </div>
                    </v-col>
                   

                </v-row>
                    
			</template>
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end" >
					<v-col cols="12" sm="3">
						<secondaryButton
						:props="cancelDeleteModal"
						></secondaryButton>
					</v-col>
                    <v-col cols="12" sm="3" v-if="!access" class="mr-sm-4">
						<primaryButton
						:props="acceptDeleteModalFALSE"
						></primaryButton>
					</v-col>
					<v-col cols="12" sm="3" v-if="access" class="mr-sm-4">
						<primaryButton
						:props="acceptDeleteModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

        <!-- EXPORT -->
        <modal :props="modalExportProps" @modalResponse="method_handler">
            <!-- Body -->
           
            <template slot="cardBody">
				
                <v-row class="mt-1">
                    <v-col class="px-0">
                        <autocompleteOneForm
                            label="EMPRESA"
                            :valueInput="exportBusinessSelected"
                            :rule="[]"
                            :items="serverItems.exportBusinessList"
                            @valueChange="(v) => {exportBusinessSelected = v;}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>
                <v-row class="mt-1">
                    <v-col class="px-0">
                        <autocompleteOneForm
                            label="ESTATUS DEL EMPLEADO"
                            :valueInput="exportStatusSelected"
                            :rule="[]"
                            :items="serverItems.estatus"
                            @valueChange="(v) => {exportStatusSelected = v;}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>
			</template>
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end" >
					<v-col cols="12" sm="3" class="">
						<secondaryButton
                            :props="cancelExportModal"
						></secondaryButton>
					</v-col>
                    
					<v-col cols="12" sm="4"  class="mr-sm-2">
						<primaryButton
						:props="acceptExportModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
// import ChipDataTableItem from "../../../components/ChipDataTableItem.vue";
import Utils from '../../../../helpers/Utils';
import PageStateManager from '../../../../helpers/PageStateManager';
export default {
	components: {
    //    'chip-item': ChipDataTableItem
    },
    data(){
		return {
            exportBusinessSelected: "ALL",
            exportStatusSelected: "ACTIVO",
            searchName: "",
            reasonInput: "",
            confimPlaceholder:"",
            confirmNameInput: "",
            confirmNameRef: "",
            loadingLink: true,
            shareValueUrl:"dfff",
            selectedCompany: PageStateManager.getState(this.$router.history.current.path, 'selectedCompany')??'',
            selectedOffice: PageStateManager.getState(this.$router.history.current.path, 'selectedOffice')??'',
            selectedStatus: PageStateManager.getState(this.$router.history.current.path, 'selectedStatus')??'ACTIVO',
			loadingCompanies: false,
            loadingOffices: false,
			actions: [
                // {
				// 	name: 'Dar de baja',
				// 	icon: {
				// 		color: 'secondary',
				// 		icon: 'mdi-account-off-outline'
				// 	},
				// 	disabled: false,
				// 	type: 'method',   //  method, external, redirect
				// 	action: 'deleteItemModal',
                //     permissions: 'employees.delete'
				// },
                // {
				// 	name: 'Compartir',
				// 	icon: {
				// 		color: 'secondary',
				// 		icon: 'mdi-share-variant-outline'
				// 	},
				// 	disabled: false,
				// 	type: 'method',   //  method, external, redirect
				// 	action: 'openShareModal',
                //     permissions: 'employees.update'
				// },
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/empleados/1/edicion',
                    permissions: 'employees.update'
				},
                {
					name: 'Estadísticas',
					icon: {
						color: 'secondary',
						icon: 'mdi-chart-box-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/empleados/1/estadisticas',
                    permissions: 'employees.show'
				},
                {
					name: 'Vacaciones',
					icon: {
						color: 'secondary',
						icon: 'mdi-umbrella-beach-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/empleados/1/estadisticas',
                    permissions: 'vacations.create'
				},
                {
					name: 'Nueva Eventualidad',
					icon: {
						color: 'secondary',
						icon: 'mdi-text-box-plus-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/empleados/1/estadisticas',
                    permissions: 'employees.create'
				},
				
			],
			tableProps: {
				headers: [
                    
				],
				items: [],
				search: '',
				loading: false
			},

            tableSearchProps:{
                headers: [
                    {
						text: 'Empleado',
						align: 'left small-text-8',
						value: 'name',
						class: 'table-b-border '
					},
					{
						text: 'Empresa',
						align: 'left small-text-8',
						value: 'company',
						class: 'table-b-border small-text-8'
					},
                    {
						text: 'Sucursal',
						align: 'left small-text-8',
						value: 'branch',
						class: 'table-b-border'
					},
                    
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false,
                elevation: '0',
                itemsPerPageStart: 5,
                
            },
			
			snackbar: {
				visible: false,
				color: '',
				text: '',
				timeout: '-1'
			},
            searchModalBtnData: {
                text: "Buscar",
                icon: "",
				to: "",
				block: true,
                click: ()=>{this.search()}
            },
            createBtnData: {
                text: "Nuevo empleado",
                icon: "mdi-plus",
				to: "EmployeeCreate",
				block: true,
                click: ()=>{}
            },
            eventBtnData:{
                text: "Nueva eventualidad",
                icon: "mdi-text-box-plus-outline",
				to: "EmployeeEventCreate",
				block: true,
                click: ()=>{}
            },

            serverItems: {
                companies: [{text: "Empresa | Grupo Merca", value: "merca"}],
                offices: [],
                estatus: [{text: "ACTIVO", value: "ACTIVO"}, {text: "INACTIVO", value: "INACTIVO"}],
                exportBusinessList: [],
            },

            modalProps: {
				visible: false,
				width: '500',
				title: 'Compartir el cuestionario para actualización de datos',
				text: 'Comparte este enlace con Dulce Ulibarri para invitarle a actualizar su información personal, este enlace tendrá una vigencia de 48 horas',
				loading: false,
				bottomLineColor: 'success',
				
			},
            cancelModal: {
                text: "Copiar Enlace",
                classes: "justi-center",
                icon: "",
				to: "",
				block: true,
				color: 'success',
                
                click: ()=>{this.copy()}
            },
            cancelExportModal: {
                text: "Cancelar",
                classes: "",
                icon: "mdi-close",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.closeExportModal()}
            },
			acceptModal: {
                text: "Aceptar",
                icon: "",
				to: "",
				block: true,
				color: 'success',
				textColor:'white',
                textColorLevel: "5",
                classes: "justi-center",
				loading:false,
                click: ()=>{this.modalProps.visible = false}
            },

            modalDeleteProps: {
				visible: false,
				width: '700',
				title: 'Baja',
				text: '¿Estás seguro de dar de baja?',
				loading: false,
				bottomLineColor: 'error',
				
			},

            modalSearchProps: {
                visible: false,
				width: '800',
				title: 'Buscar Empleado',
				text: '',
				loading: false,
                hasCloseBtn: true,
				bottomLineColor: 'primary',
            },

            modalExportProps: {
                visible: false,
				width: '600',
				title: 'Exportar lista de empleados',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
            },
			
			cancelDeleteModal: {
                text: "NO, REGRESAR",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalDeleteProps.visible = false}
            },
			acceptDeleteModal: {
                text: "Si, dar de baja",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
				loading:false,
                click: ()=>{this.delete()}
            },
			acceptExportModal: {
                text: "Descargar",
                icon: "mdi-content-save-outline",
				to: "",
				block: true,
				color: 'primary',
				
				loading:false,
                click: ()=>{this.exportEmployees()}
            },
			acceptDeleteModalFALSE: {
                text: "ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
				loading:false,
                disabled: true,
                click: ()=>{}
            },
		}
	},
    watch: {
        /**
         * Reset values on "type" change
         */
        [`selectedCompany`]: function () {
            console.log(this.selectedCompany)
            PageStateManager.setState(this.$router.history.current.path, 'selectedCompany', this.selectedCompany)
        },
        [`selectedOffice`]: function () {
            console.log(this.selectedOffice)
            PageStateManager.setState(this.$router.history.current.path, 'selectedOffice', this.selectedOffice)
        },
        [`selectedStatus`]: function () {
            PageStateManager.setState(this.$router.history.current.path, 'selectedStatus', this.selectedStatus)
        },
        [`confirmNameInput`]: function () {
            if(this.confirmNameRef.toUpperCase() == this.confirmNameInput.toUpperCase()){
                this.acceptDeleteModal.disabled = false;
            // console.log(this.confirmNameRef, this.confirmNameInput, this.acceptDeleteModal.disabled)
                return;
            }
            this.acceptDeleteModal.disabled = true;
            // console.log(this.confirmNameRef, this.confirmNameInput, this.acceptDeleteModal.disabled)
        },
        
        // prevHeight: function(v){
        //     if(v != 0)
        //         this.prevHeight = 0;
        // }
    },
    computed: {
        access: function () {
            if(this.confirmNameRef.toUpperCase() == this.confirmNameInput.toUpperCase()){
                
                if(this.reasonInput.trim() != "")
                    return true;
            }
            return false;
        },
    },

	mounted(){
        if(this.$gates.hasPermission('area_rol_user.show')){
            this.$router.push({name: 'EmployeesArea'});
        }else{
            this.initTable();
            this.getCompanies();

        }
		//this.index()
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
        goToLayoff(){
            this.$router.push({name: 'EmployeesLayoff'});
        },
        initTable(){
            if(this.$gates.hasPermission('area_rol_user.show')){
                this.tableProps.headers = [
                    {
                        text: 'Empleado',
                        align: 'left small-th-employees',
                        value: 'employe_number',
                        class: 'table-b-border white-space-normal small-th-employees'
                    },
                    {
                        text: 'Nombre',
                        align: 'left small-th-employees',
                        value: 'name',
                        class: 'table-b-border white-space-normal small-th-employees'
                    },
                     {
                        text: 'Puesto',
                        align: 'center small-th-employees',
                        value: 'position',
                        class: 'table-b-border white-space-normal'
                    },
                   
                    
                    {
                        text: 'Ingreso',
                        align: 'left small-th-employees',
                        value: 'date_i',
                        class: 'table-b-border white-space-normal'
                    },

                    {
                        text: 'Cumpleaños',
                        align: 'left small-th-employees',
                        value: 'date_birthdate_text',
                        class: 'table-b-border white-space-normal'
                    },
                    {
                        text: 'Vacaciones Pend',
                        align: 'left small-th-employees',
                        value: 'vacations_text',
                        class: 'table-b-border white-space-normal'
                    },
                   
                    //{
                        //  text: 'Estatus',
                        // align: 'left small-text-8',
                        // value: 'status',
                        //class: 'table-b-border'
                    //},
                    {
                        text: 'Acciones',
                        align: 'right',
                        sortable: false,
                        value: 'actions',
                        class: 'table-b-border'
                    }
                ]
                return;
            }
            this.tableProps.headers = [
                {
                    text: 'No.\n Empleado',
                    align: 'left small-th-employees',
                    value: 'employe_number',
                    class: 'table-b-border white-space-normal small-th-employees'
                },
                {
                    text: 'Nombre',
                    align: 'left small-th-employees',
                    value: 'name',
                    class: 'table-b-border white-space-normal small-th-employees'
                },
                {
                    text: 'Correo',
                    align: 'center small-th-employees',
                    value: 'email',
                    class: 'table-b-border white-space-normal small-th-employees'
                },
                {
                    text: 'Teléfono',
                    align: 'center small-th-employees',
                    value: 'phone',
                    class: 'table-b-border white-space-normal small-th-employees'
                },
                {
                    text: 'Puesto',
                    align: 'center small-th-employees',
                    value: 'position',
                    class: 'table-b-border white-space-normal'
                },
                {
                    text: 'Sucursal',
                    align: 'center small-th-employees',
                    value: 'office',
                    class: 'table-b-border white-space-normal'
                },
                {
                    text: 'Fecha\ningreso',
                    align: 'left small-th-employees',
                    value: 'date_i',
                    class: 'table-b-border white-space-normal'
                },
                {
                    text: 'Docs',
                    align: 'center small-th-employees',
                    value: 'documentation',
                    class: 'table-b-border white-space-normal'
                },
                //{
                    //  text: 'Estatus',
                    // align: 'left small-text-8',
                    // value: 'status',
                    //class: 'table-b-border'
                //},
                {
                    text: 'Acciones',
                    align: 'right',
                    sortable: false,
                    value: 'actions',
                    class: 'table-b-border'
                }
            ]
        },
        copy() {
            this.$refs.clone.focus();
            navigator.clipboard.writeText(this.shareValueUrl);
        },
		index() {
			this.tableProps.loading = true;
			//call api
			this.$api.employees.indexByBusiness(this.selectedCompany, this.selectedOffice, this.selectedStatus)
				.then((resp) =>{
					console.log(resp, "employees index");
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))

                        // if(this.selectedStatus == "INACTIVO"){
                        //     console.log(x.actions[0])
                        //     x.actions[0].disabled = true
                        // }
						
						x.actions[0].action = { name: 'EmployeeEdit', params: { id: x.id } }
						x.actions[1].action = { name: 'EmployeeStatistic', params: { id: x.id } }
                        x.actions[2].action = { name: 'EmployeeVacationsCreate', params: { id: x.id } }
                        x.actions[3].action = { name: 'EmployeeEventCreate', params: { id: x.id } }
						
						
						x['parameters'] = { id: x.id, name: x.name};
						x.position = x.current_contract?.position?.name??'';
						x.date_i = Utils.globalDateFormat(x.current_contract?.admission_date);
                        x.office = x.current_contract?.branch_office?.name??'';

						x.documentation = this.checkDocumentation(x.documents);
						// x.status = this.getStatus(x.current_contract.type, x.current_contract.start_date, x.current_contract.end_date);
						
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error employees index");
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
			//console.log(test);
			
		},


		getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
				.then((resp) =>{
                    
                    //index filter
					this.serverItems.companies = resp.data.map((comp) => {
						return {value: comp.id, text: `${comp.name}`}
					});

                    

					if(this.serverItems.companies.length >= 1){
						if(this.selectedCompany == "")
                            this.selectedCompany = this.serverItems.companies[0].value;
						this.index();
                        this.getBranchOffices();
					}

                    //export filter
                    this.serverItems.exportBusinessList.push({value: "ALL", text: "TODAS"});
                    this.serverItems.exportBusinessList.push(
                        ...resp.data.map((comp) => {
                            return {...comp, value:comp.id, text: `${comp.name}`}
                        })
                    );
					
				})
				.catch((error) =>{
					console.log(error, "error getBusinessSelect");
				})
				.finally(() => {
                    this.loadingCompanies = false;
				})
			;
        },

        getBranchOffices()
        {
            this.serverItems.offices = [];
            this.loadingOffices = true;
            this.$api.locations.getLocationsByBusiness(this.selectedCompany)
                .then((resp) =>{
                    this.serverItems.offices.push({text: "TODAS", value: ""});
                    resp.data.forEach(item => {
                        this.serverItems.offices.push({text: item.name, value: item.id});
                    });
					
					console.log(resp, 99);
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
                    this.loadingOffices = false;
				})
                
            ;
        },
		/**
		 * Set color to chip item
		 */
        getColorDocumentation (status) {
            if(status == "Completa")
				return 'success';
			if(status == "Incompleta")
				return 'warning';
        },

		/**
		 * Set status by dates
		 */
		getStatus(contractType = '', startDate, endDate){
			if(endDate == null){
				return 'Activo';
			}
			if(contractType == 'PERIODO DE PRUEBA'){
				const now = new Date();
				now.setHours(0,0,0,0);
				const end = Utils.convertTZ(endDate, 'America/Mexico_city');
				//end.setHours(0,0,0,0);
				// console.log()
				return now <= end ? 'Activo' : 'Inactivo';
			}
			return 'Activo';
		},
		// convertTZ(date, tzString) {
		// 	return new Date((typeof date === "string" ? new Date(date+' 00:00:00') : date).toLocaleString("en-US", {timeZone: tzString}));   
		// },

		/**
		 * check all "mandatory" documentation
		 */
		checkDocumentation(documents){
			if(documents.length == 0)
				return 'Incompleta';

			for (let i = 0; i < documents.length; i++) {
                const doc = documents[i];
                if(doc.required == true && (doc.file_url == "" || doc.file_url == null))
                    return "Incompleta";
            }
			
			return 'Completa';
		},


        openShareModal(parameters)
		{
			this.selectedID = parameters.id;
			this.modalProps = {
                ...this.modalProps,
				visible: true,
				width: '500',
				// title: 'Compartir el cuestionario',
				// text: 'Al compartir el cuestionario, este se enviará a todos los empleados adscritos a los registros patronales señalados. \n ¿Estás seguro de continuar?',
				loading: false,
				bottomLineColor: 'success',
				
			}
			this.modalProps.visible = true;
			this.loadingLink = true;
            this.$api.employees.getToken(this.selectedID)
                .then((resp) =>{
                  
					console.log(resp, 99);
                    this.shareValueUrl = `${process.env.VUE_APP_SELF_URL}/update-employee/${resp.data.token}`;
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
                    this.loadingLink = false;
				})
                
            ;
		},


        deleteItemModal(parameters){
			this.selectedID = parameters.id;
            console.log(parameters.name)
            this.confirmNameInput = "";
            this.confimPlaceholder = parameters.name.toUpperCase();
            this.confirmNameRef = parameters.name.toUpperCase();
            this.reasonInput = "";
            let msg = ``;
            let color = 'error';
            let title = "BAJA";
            this.acceptDeleteModal.color = 'error';
            this.acceptDeleteModal.disabled = true;
            this.acceptDeleteModal.textColor= 'white';
            this.acceptDeleteModal.text = "ELIMINAR";

            if(this.selectedStatus == "INACTIVO"){
                msg = `¿Estás seguro dar de alta?`
                this.acceptDeleteModal.color = 'primary';
				this.acceptDeleteModal.textColor= 'black';
                this.acceptDeleteModal.text = "Si, dar de alta";
                color = "white";
                title = "Alta"
            }

			this.modalDeleteProps = {
				...this.modalDeleteProps,
				text: msg,
                bottomLineColor: color,
                title
				
			}
			this.modalDeleteProps.visible = true;
			
		},
		delete(){
			this.acceptDeleteModal.loading = true;
			this.$api.employees.changeStatus(this.selectedID, this.reasonInput)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha actualizado su estatus`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				

				this.$store.dispatch('snackbarError', `Error al dar de baja. Intenta de nuevo`);
			})
			.finally(() => {
				this.modalDeleteProps.visible = false;
				this.acceptDeleteModal.loading = false;
			})
		},


        openModalSearch()
        {
            this.tableSearchProps.itemsPerPageStart = 5;
            this.tableSearchProps.items = [];
            this.searchName = "";
            this.modalSearchProps.visible = true;
            setTimeout(() => {
                this.$refs.searchInput.$children[0].focus()
            });
            
        },

        search()
        {
            if(this.searchName.trim() == "")
                return;
            
            this.tableSearchProps.loading = true;
            
            this.$api.employees.search(this.searchName)
				.then((resp) =>{
					this.tableSearchProps.items = resp.data.map((x) => {
						let acts = JSON.parse(JSON.stringify(this.actions))
                        x['actions'] = acts;
						x.actions[0].action = { name: 'EmployeeEdit', params: { id: x.id } }
						x.actions[1].action = { name: 'EmployeeStatistic', params: { id: x.id } }
                        x.actions[2].action = { name: 'EmployeeVacationsCreate', params: { id: x.id } }
                        x.actions[3].action = { name: 'EmployeeEventCreate', params: { id: x.id } }
						
						
						
						x['parameters'] = { id: x.id, name: x.name};

                        x.company = x.current_contract?.branch_office?.company?.name??"";
                        x.branch = x.current_contract?.branch_office.name??"";
						
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error employees index");
				})
				.finally(() => {
                    this.tableSearchProps.loading = false;
				})
			;
        },

        openExportModal()
        {
            
            this.modalExportProps.visible = true;
            this.exportBusinessSelected = "ALL";
            this.exportStatusSelected = "ACTIVO"
           
            
        },

        closeExportModal()
        {
            this.modalExportProps.visible = false;
        },

        exportEmployees()
        {
            if(this.exportBusinessSelected == "")
                return;
            if(this.exportStatusSelected == "")
                return;

            this.acceptExportModal.loading = true;
            this.$api.employees.exportEmployees(this.exportBusinessSelected, this.exportStatusSelected.toUpperCase())
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `employees_${this.exportBusinessSelected}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.acceptExportModal.loading = false;
                    this.$store.state.loading = false;
                    this.closeExportModal();
                })
            ;

        }

         

    }
}
</script>

<style lang="scss">

    .container-input-share{
        border: 1px solid gray;
    }

    .outside{
        margin-top: -7px !important;
    }

    .justi-center{
        justify-content: center;
    }

    .width-fit{
        max-width: fit-content;
    
    }

    .chip-employees-index{
        min-width: 32px;
        justify-content: center;
    }

  
    
    // @media (max-width: $display-breakpoints-xl)  {
    //     .width-fit{
    //         max-width: fit-content !important;
    
    //     }
    // }

    // @media (max-width: $display-breakpoints-sm)  {
    //     .width-fit{
    //         max-width: unset;
    
    //     }
    // }


    
</style>