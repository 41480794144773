<template>
    <div :key="formKey">
        <v-row>
            <v-col>
                <details-item :detailsItems="generalInfo"></details-item>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <autocompleteOneForm
                    label="Tipo de mantenimiento"
                    :valueInput="values.type"
                    :rule="rules.required"
                    :items="serverItems.types"
                    :errorMessages="serverErrors.type"
                    @valueChange="(v) => {values.type = v; delete serverErrors.type}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
   
        <v-row>
            <v-col>
                <textFieldForm 
                    @valueChange="(v) => {values.reason = v.trim(); delete serverErrors['reason']}" 
                    :valueInput="values.reason" 
                    :rule="rules.required" 
                    label="Motivo del mantenimiento"
                    :errorMessages="serverErrors['reason']"
                    :disabled="values.type == 'PREVENTIVO'"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <textAreaForm
                    @valueChange="(v) => {values.diagnosis = v.trim(); delete serverErrors.diagnosis}" 
                    :valueInput="values.diagnosis" 
                    :rule="rules.required" 
                    label="Diagnóstico"
                    :errorMessages="serverErrors.diagnosis"
                    rows="3"
                    class=""
                ></textAreaForm>
            </v-col>
        </v-row>
         <v-row>
            <v-col>
                <textAreaForm
                    @valueChange="(v) => {values.observations = v.trim(); delete serverErrors.observations}" 
                    :valueInput="values.observations" 
                    :rule="rules.required" 
                    label="Observaciones"
                    :errorMessages="serverErrors.observations"
                    rows="3"
                    class=""
                ></textAreaForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col>
              <fileInputForm
                label="Fotografías"
                :multiple="true"
                :valueInput="values.photo_files"
                :url="''"
                :rule="rules.fileRequired"
                @valueChange="(v) => {values.photo_files = v; delete serverErrors.photo_files}"
                :errorMessages="serverErrors.photo_files"
                v-on:update:url="values.files_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>
                   
            
        <v-row v-for="(item, index) in values.imgs" :key="index">
            <v-col>
                <imagePrevForm
                    :nameImg="item.name"
                    :url="item.url"
                    v-on:update:url="item.url = $event; removeImg(index);"
                ></imagePrevForm>
            </v-col>
        </v-row>



        <checkboxForm
            label="Programar recordatorio para siguiente mantenimiento"
            :valueInput="values.check_maintenance"
            :rule="[]"
            @valueChange="(v) => {values.check_maintenance = (v == true ? 1:0); }"
            class="mx-0 my-4 pt-4"
        ></checkboxForm>
       
        <v-row v-if="values.check_maintenance">
            <v-col>
                <datePickerForm
                    label="Fecha del recordatorio"
                    :valueInput="values.next_maintenance_date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.next_maintenance_date = v; delete serverErrors.next_maintenance_date}"
                    :errorMessages="serverErrors.next_maintenance_date"
                ></datePickerForm>
            </v-col>
            
        </v-row>
    
               
               
               

    </div>
</template>

<script>
import DetailsItem from "../../../components/DetailsItem.vue";
/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
    components: {
        DetailsItem,
    },
    data(){
        return {
            generalInfo: [],
            formKey: 324,
            serverItems:{
                types:[
                    {text: "Preventivo", value: "PREVENTIVO"},
                    {text: "Correctivo", value: "CORRECTIVO"},
                ]
            },
            rules: {
                required: [
					v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
				],
                fileRequired: [
                    v => {
                        if(this.values.photo_files.length == 0 && this.values.file_url == "")
                            return "El campo es requerido"
                        return true
                    }
                ]
				
            },
            
        }
    },
    watch: {
      /**
       * Reset values on "type" change
       */
      [`values.type`]: function (v) {
        this.values.reason = "";
        if(v == "PREVENTIVO"){
            this.values.reason = "PROGRAMADO";
        }
        this.formKey = this.formKey + 1;
      },
     
      // prevHeight: function(v){
      //     if(v != 0)
      //         this.prevHeight = 0;
      // }
    },
  
    mounted(){
        this.loadGeneralInfo();
    },
    methods: {
        loadGeneralInfo(){
            this.generalInfo = [
                { concepto: 'No.', index: 'id', valor: this.values.num??"" },
                { concepto: 'Descripción', index: 'id', valor: this.values.description },
                { concepto: 'Marca', index: 'id', valor: this.values.brand },
            ];
        },
        removeImg(index)
        {
            let deleted = JSON.parse(JSON.stringify(this.values.imgs[index]));
            this.values.imgs.splice(index, 1);
            this.values.delete_photo_files.push({photo_path: deleted.path});

        },
    }
}
</script>

<style>

</style>